<template>
     <div class="Client">
        <!-- BEGIN: Content-->
        <div class="app-content content ">
            <div class="content-overlay"></div>
            <div class="header-navbar-shadow"></div>
            <div class="content-wrapper container-xxl p-0">

                <banner title="Income Tax Return Filing" :breadcrumb="[
                    { label: 'Category ', url: 'http://localhost:8080/retailer/client' },
                    { label: 'Individual ' },
                   
                ]">
                </banner>
                <div class="row" style="margin-top: -45px;">
                    <div class="col-xl-6 col-lg-6 col-md-6 col-12" style="padding:5px 5px 5px 5px ">
                        <div class="card">
                            <div class="card-body">
                                <h3>Step-2 : Other Informations</h3>
                                <div class="progress progress-bar-danger">
                                    <div class="progress-bar" role="progressbar" aria-valuenow="40" aria-valuemin="40"
                                        aria-valuemax="100" style="width: 40%">40%</div>
                                </div>
                                <h2>Select Sources of Income</h2>
                                <div class="row inner-card" v-for="(modInfo, index) in modulesInfo" :key="index">
                                    <div class="col-11" v-if="modInfo.service_module">
                                        <h3 class="heading-red">{{ index + 1 }}.
                                            {{ modInfo.service_module.moduleName }}</h3>
                                        <p>{{ modInfo.service_module.remark }}</p>
                                    </div>
                                    <div class="col-1">
                                        <div class="form-check form-check-inline form-check-danger text-center">
                                            <input @click="getChargesHere($event, modInfo)" class="form-check-input"
                                                type="checkbox" id="inlineCheckbox1" />
                                        </div>
                                    </div>
                                </div>


                                <div class="row">
                                    <div class="col-6 text-start">
                                        <router-link to="/itr">
                                            <button type="button" @click="redirectBack" class="btn btn-sm text-white"
                                                style="background-color:#00364f;width:70%">
                                                <strong>BACK</strong></button>
                                        </router-link>
                                    </div>
                                    <div class="col-6 text-end">

                                        <button @click="getIncomeRecord" class="btn btn-sm  text-white"
                                            style="background-color:#f21300;width:70%;"><strong>CONTINUE</strong>
                                        </button>

                                        <!-- </router-link> -->
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-12" style="padding:5px 5px 5px 5px ">
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-12">
                                        <h2 class="heading">FAQ's</h2>
                                        <div class="accordion accordion-margin accordion-border" id="accordionMargin">
                                            <div class="accordion-item">
                                                <h2 class="accordion-header" id="headingMarginOne">
                                                    <button class="accordion-button collapsed" type="button"
                                                        data-bs-toggle="collapse" data-bs-target="#accordionMarginOne"
                                                        aria-expanded="false" aria-controls="accordionMarginOne">
                                                        <i data-feather='plus' class="accordian-plus"></i> What
                                                        is Salary Income?
                                                    </button>
                                                </h2>
                                                <div id="accordionMarginOne" class="accordion-collapse collapse"
                                                    aria-labelledby="headingMarginOne"
                                                    data-bs-parent="#accordionMargin">
                                                    <div class="accordion-body">
                                                        Pastry pudding cookie toffee bonbon jujubes jujubes
                                                        powder topping. Jelly beans gummi bears sweet roll
                                                        bonbon muffin liquorice. Wafer lollipop sesame snaps.
                                                        Brownie macaroon cookie muffin cupcake candy
                                                        caramels tiramisu. Oat cake chocolate cake sweet jelly-o
                                                        brownie biscuit marzipan. Jujubes donut
                                                        marzipan chocolate bar. Jujubes sugar plum jelly beans
                                                        tiramisu icing cheesecake.
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="accordion-item">
                                                <h2 class="accordion-header" id="headingMarginFour">
                                                    <button class="accordion-button collapsed" type="button"
                                                        data-bs-toggle="collapse" data-bs-target="#accordionMarginFour"
                                                        aria-expanded="false" aria-controls="accordionMarginFour">
                                                        <i data-feather='plus' class="accordian-plus"></i> What
                                                        is Business Income?
                                                    </button>
                                                </h2>
                                                <div id="accordionMarginFour" class="accordion-collapse collapse"
                                                    aria-labelledby="headingMarginFour"
                                                    data-bs-parent="#accordionMargin">
                                                    <div class="accordion-body">
                                                        Cheesecake muffin cupcake dragée lemon drops tiramisu
                                                        cake gummies chocolate cake. Marshmallow tart
                                                        croissant. Tart dessert tiramisu marzipan lollipop lemon
                                                        drops. Cake bonbon bonbon gummi bears topping
                                                        jelly beans brownie jujubes muffin. Donut croissant
                                                        jelly-o cake marzipan. Liquorice marzipan cookie
                                                        wafer tootsie roll. Tootsie roll sweet cupcake.
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="accordion-item">
                                                <h2 class="accordion-header" id="headingMarginFive">
                                                    <button class="accordion-button collapsed" type="button"
                                                        data-bs-toggle="collapse" data-bs-target="#accordionMarginFive"
                                                        aria-expanded="false" aria-controls="accordionMarginFive">
                                                        <i data-feather='plus' class="accordian-plus"></i> What
                                                        are Other Sources of Income?
                                                    </button>
                                                </h2>
                                                <div id="accordionMarginFive" class="accordion-collapse collapse"
                                                    aria-labelledby="headingMarginFive"
                                                    data-bs-parent="#accordionMargin">
                                                    <div class="accordion-body">
                                                        Cheesecake muffin cupcake dragée lemon drops tiramisu
                                                        cake gummies chocolate cake. Marshmallow tart
                                                        croissant. Tart dessert tiramisu marzipan lollipop lemon
                                                        drops. Cake bonbon bonbon gummi bears topping
                                                        jelly beans brownie jujubes muffin. Donut croissant
                                                        jelly-o cake marzipan. Liquorice marzipan cookie
                                                        wafer tootsie roll. Tootsie roll sweet cupcake.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <h2 class="heading">Videos</h2>
                                        <div class="video-player" id="plyr-video-player" style="margin: 10px;">
                                            <iframe src="https://www.youtube.com/embed/femaL6lGw1o" width="100%"
                                                height="360" allowfullscreen allow="autoplay"></iframe>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



              
              

            </div>

        </div>
    </div>


    <div class="modal fade" id="exampleModalCenter" tabindex="-1" aria-labelledby="exampleModalCenterTitle"
            aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalCenterTitle"><strong style="color:#00364f;">Service
                                Charges</strong></h5>
                        <!-- <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> -->
                    </div>
                    <div class="modal-body">
                        <p>
                            Total Service Charge : <strong style="color:#00364f;">{{ charges.serviceCharge + charges.serviceChargeGst }}</strong>
                        </p>
                    </div>
                    <div class="modal-footer">


                        <button type="button" data-bs-dismiss="modal" class="btn btn-sm text-white"
                            style="background-color:#00364f;"> CLOSE</button>
                        <button @click="goToCustomerDetails(charges.serviceCharge + charges.serviceChargeGst)" class="btn btn-sm text-white"
                            style="background-color:#f21300;">CONTINUE </button>
                        <!-- <button type="button" class="btn btn-primary" data-bs-dismiss="modal">Accept</button> -->
                    </div>
                </div>
            </div>
        </div>


</template>

<script>
       import Banner from '../../../../components/retailer/comman/Banner.vue'
import jQuery from "jquery";
let $ = jQuery
export default {
    name: 'Itr2',
    components: {
        Banner,
    },
    data() {
        return {
            // termCondition: 'false1'

            sourceOfIncome: [],
            charges: {},
            form: {
                serviceModulesIds: [],
                service_id: null,
            },

            modulesInfo: [],
        }
    },
    methods: {
        goToCustomerDetails(amt) {
            var itr = {}
            itr.serviceCharge = ''
            itr = JSON.parse(localStorage.getItem('itr'))
            itr.serviceCharge = amt
            localStorage.setItem('itr', JSON.stringify(itr))
            $('#exampleModalCenter').modal('hide')
            this.$router.push({ name: 'Itr3' })

        },

    getChargesHere(event , value){
      
        this.form.service_id = value.service_id
      console.log(event.target.checked)
      if(event.target.checked == true){
       this.form.serviceModulesIds.push(value.service_module.id)
        console.log(this.form.serviceModulesIds)
      }else if(event.target.checked == false){
        this.form.serviceModulesIds.splice(this.form.serviceModulesIds.indexOf(value.service_module.id), 1);
        console.log(this.form.serviceModulesIds)
      }
  
    },
    getIncomeRecord() {
            this.$axios
                .post('retailer/service/charge', this.form, { headers: { Authorization: 'Bearer ' + localStorage.accessToken } })
                .then(res => {
                    console.log('----------------service chargs ----0')
                    this.charges = res.data
                    console.log(this.charges)
                    $('#exampleModalCenter').modal('show')
                })
                .catch((error) => {
                    console.log(error)
                })


        },
        redirectBack() {
            $('#exampleModalCenter').modal('hide')
            this.$router.push({ name: 'Itr' })
        },

        getModule() {
            this.$axios
                .get('retailer/servicemodule/charge?service_id=38', { headers: { Authorization: 'Bearer ' + localStorage.accessToken } })
                .then(res => {
                    console.log('module charges')
                    console.log(res.data.data.data)
                    this.modulesInfo = res.data.data.data
                })
        }


    },
    created() {
        if (this.$route.meta.redirectIfLoggedIn == 'true' && localStorage.getItem('accessToken') == null) {
            this.$router.push({ name: 'Login' })
        } else {
            this.getModule()
        }

    },
}
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");
.accordian-plus {
    width: 20px;
    height: 20px;
    stroke: red;
    stroke-width: 4;
    stroke-linecap: round;
    stroke-linejoin: round;
    fill: none;
    font-family: "Poppins", sans-serif;
}

.p-text {
    color: #fff;
    /* font-family: poppins, Sans-serif; */
    font-size: 15px;
    font-weight: 500;
    text-align: center;
    font-family: "Poppins", sans-serif;
}

.bg-image {
    background-color: #fff;
    background-image: url(https://apk.mudhrape.com/wp-content/uploads/2022/01/background.png);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 15px 0 120px;
    /* text-align: center; */
    color: white;
    /* background-size: 100% 100%;
    width: 100%; */
    font-family: "Poppins", sans-serif;
}

.h2-heading {
    color: white;
   font-family: "Poppins", sans-serif;
    font-size: 40px;
    font-weight: 600;
    text-align: center;
}

@media (max-width: 767px) {
    .h2-heading {
        font-size: 22px;
        font-family: "Poppins", sans-serif;
    }
}

.heading {
    color: #00364f;
   font-family: "Poppins", sans-serif;
    font-weight: 600;
    margin-bottom: 20px;
    padding: 20px 0;
}

.inner-card {
    box-shadow: 0px 0px 3px 0px rgb(0 0 0 / 50%);
    margin-top: 5px;
    margin-bottom: 5px;
    border-radius: 5px 5px 5px 5px;
    padding: 10px;
}

.heading-red {
    color: #f21300;
   font-family: "Poppins", sans-serif;
    font-size: 20px;
    font-weight: 600;
}
</style>